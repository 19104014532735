import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';
import { buildPaginatedResponse } from 'lib/pagination';
import { orderBy, get } from 'lodash';

export const clinicianFieldsFragment = `
  uid
  name
  phone
  email
  isCaregiver
  designation
  communicationMethod
  deactivatedOn
  profilePhoto
  metadata
  isActivated
  role {
    uid
    name
    permissions
  }
  caregiverRelations {
    patientForm {
      uid
      patient {
        uid
        name
      }
    }
  }
`;

const GRAPHQL_DOCUMENT = gql`
  query cleoClinicians(
    $clinicianNameContains: String,
    $activated: Boolean,
    $communicationMethod: CleoClinicianCommunicationMethodChoices
    $designation: String
    $orderField: ClinicianOrderBy
    $orderDesc: Boolean
    $first: Int
    $offset: Int
    $roleUid: String
    $isCaregiver: Boolean
  ) {
    cleoClinicians(
      first: $first
      offset: $offset
      sortBy: $orderField
      sortDesc: $orderDesc
      name_Icontains: $clinicianNameContains,
      deactivatedOn_Isnull: $activated,
      communicationMethod: $communicationMethod
      designation: $designation
      role_Uid: $roleUid
      isCaregiver: $isCaregiver
  ) {
      edges {
        node {
          ${clinicianFieldsFragment}
        }
      }
      pageInfo {
       endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;

export const buildClinicalUserFilters = ({ pages = [] }) => {
  const users = pages
    .flat()
    .filter(Boolean)
    .filter(({ isCaregiver }) => !isCaregiver)
    .map((user) => ({
      id: `${user.uid}`,
      label: user.name,
      toParams: {
        clinicianIcUid: [user.uid],
      },
    }));
  const orderFn = ({ label }) => label;

  return [...orderBy(users, orderFn)];
};

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  filters: {
    clinicalUsers: {
      id: 'clinicalUsers',
      title: 'Clinical users',
      multiSelect: true,
      data: buildClinicalUserFilters,
    },
  },
  baseQueryKey: 'clinicians',
  paginate: 'offset',
  select: (data) => buildPaginatedResponse(data, 'cleoClinicians'),
  getNextPageParam: (response = {}, allPages) => {
    const hasNextPage = get(
      response?.cleoClinicians,
      'pageInfo.hasNextPage',
      false
    );
    if (!hasNextPage) {
      return undefined;
    }
    const currentOffset = allPages.flatMap(({ cleoClinicians }) =>
      get(cleoClinicians, 'edges', [])
    ).length;
    return currentOffset;
  },
  keepPreviousData: true,
});
