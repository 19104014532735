import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  query cleoMessageTemplateSets(
    $first: Int
    $offset: Int
    $communicationMethods: [CommunicationMethod!]
  ) {
    cleoMessageTemplateSets(
      first: $first
      offset: $offset
      communicationMethods: $communicationMethods
    ) {
      totalCount
      edges {
        node {
          uid
          name
          purpose
          userVariables
          requirePatientEvent
          requirePatientForm
          templates {
            communicationMethod
            language
            messages
          }
        }
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'messageTemplates',
  select: ({ cleoMessageTemplateSets }) => ({
    totalCount: cleoMessageTemplateSets?.totalCount || 0,
    pages: cleoMessageTemplateSets?.edges?.map((v) => v.node) || [],
  }),
  keepPreviousData: true,
});
